html {
  background-color: rgb(203, 203, 203);
}
abbr {
  cursor: help;
}
.App {
  text-align: center;
  --card-height: 10px;
}

/* start icon */
.vertical-timeline-element--work.start {
  --fore-color: #fff;
  --back-color: #348027;
}
.vertical-timeline-element-icon.start {
  background-color: var(--back-color);
  color: var(--fore-color);
}
.vertical-timeline-element--work.start >
.vertical-timeline-element-content {
  border-top: var(--card-height) solid var(--back-color);
}

/* event icon */
.vertical-timeline-element--work.event {
  --fore-color: #76cbff;
  --back-color: #fff;
}
.vertical-timeline-element-icon.event {
  background-color: var(--back-color);
  color: var(--fore-color);
}
.vertical-timeline-element--work.event >
.vertical-timeline-element-content {
  border-top: var(--card-height) solid var(--fore-color);
}

/* battle icon */
.vertical-timeline-element--work.battle {
  --fore-color: #ffdac8;
  --back-color: rgb(156, 0, 0);
}
.vertical-timeline-element-icon.battle {
  background-color: var(--back-color);
  color: var(--fore-color);
}
.vertical-timeline-element--work.battle >
.vertical-timeline-element-content {
  border-top: var(--card-height) solid var(--back-color);
}

/* join icon */
.vertical-timeline-element--work.join {
  --fore-color: rgb(0, 149, 0);
  --back-color: #ffffff;
}
.vertical-timeline-element-icon.join {
  background-color: var(--back-color);
  color: var(--fore-color);
}
.vertical-timeline-element--work.join >
.vertical-timeline-element-content {
  border-top: var(--card-height) solid var(--fore-color);
}

/* info icon */
.vertical-timeline-element--work.info {
  --fore-color: rgb(255, 255, 255);
  --back-color: #2631ff;
}
.vertical-timeline-element-icon.info {
  background-color: var(--back-color);
  color: var(--fore-color);
}
.vertical-timeline-element--work.info >
.vertical-timeline-element-content {
  border-top: var(--card-height) solid var(--back-color);
}

